import React, { useState } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Button, Section, Box, Input } from "../components/Core";

import PageWrapper from "../components/PageWrapper";
import { device } from "../utils";

const FormStyled = styled.form``;

const WidgetWrapper = styled(Box)`
  border-radius: 10px;
  background-color: #f7f7fb;
  padding-top: 80px;
  padding-bottom: 30px;
  padding-left: 50px;
  padding-right: 50px;
  @media ${device.lg} {
    padding-left: 140px;
    padding-right: 150px;
  }
  @media ${device.xl} {
    padding-left: 150px;
    padding-right: 150px;
  }
`;

const Contact2 = () => {
  const [submitted, setSubmitted] = useState(false);
  const GATEWAY_URL =
    "https://s5kvhh9jm8.execute-api.us-east-1.amazonaws.com/default/website_help_submit";
  const [data, setData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const onSubmit = async () => {
    try {
      await fetch(GATEWAY_URL, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        body: JSON.stringify(data),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      setSubmitted(true);
    } catch (error) {
      console.log("err", error);
    }
  };

  const showThankYou = <div>Thank you! We will be in touch soon.</div>;

  const showForm = (
    <form method="post">
      <Box mb={4}>
        <Title variant="card" fontSize="18px" as="label" htmlFor="nameput">
          Your name
        </Title>
        <Input type="text" id="nameput" name="name" required />
      </Box>
      <Box mb={4}>
        <Title variant="card" fontSize="18px" as="label" htmlFor="emailput">
          Email address
        </Title>
        <Input type="email" id="emailput" name="email" required />
      </Box>

      <Box mb={4}>
        <Title variant="card" fontSize="18px" as="label" htmlFor="serviceput">
          Subject
        </Title>
        <Input type="text" id="serviceput" name="service" required />
      </Box>

      <Box mb={4}>
        <Title variant="card" fontSize="18px" as="label" htmlFor="messageput">
          Message
        </Title>
        <Input
          type="text"
          as="textarea"
          placeholder="Write your message here"
          rows={4}
          id="messageput"
          name="message"
          required
        />
      </Box>

      <Button width="100%" type="submit" borderRadius={10}>
        Send Message
      </Button>
    </form>
  );

  return (
    <>
      <PageWrapper footerDark>
        <Section>
          <div className="pt-5"></div>
          <Container>
            <Row className="justify-content-center">
              <Col lg={6} className="order-lg-1 pt-5 mt-4">
                <div>
                  <Title className="mb-5 text-center">
                    We want to hear from you!
                  </Title>
                  {submitted ? showThankYou : showForm}
                </div>
              </Col>
            </Row>
          </Container>
        </Section>
      </PageWrapper>
    </>
  );
};
export default Contact2;
